<template>
  <div class="guide">
    <Header
      back="返回"
      title="产品列表"
      index="首页"
      titleOne="政策管理"
      titleTwo="产品列表"
      beforeTitle="指南"
    />
    <div class="content">
      <h1>{{ this.productform.title }}</h1>
      <h2>产品类型</h2>
      <p>{{ this.productform.type }}</p>
      <h2>关键词</h2>
      <p>{{ this.productform.keywords }}</p>
      <h2>产品依据</h2>
      <p>
        <el-link :href="'http://' + this.productform.url" target="_blank">{{
          this.productform.url
        }}</el-link>
      </p>
      <h2>所属区域</h2>
      <p>江苏省-苏州市-相城区</p>
      <h2>产品描述</h2>
      <p>{{ this.productform.description }}</p>
      <h2>申请条件</h2>
      <p>{{ this.productform.conditions }}</p>
      <h2>奖励</h2>
      <p>{{ this.productform.award }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productform: {
        title: "",
        type: "",
        keywords: "",
        url: "",
        province_id: "",
        city_id: "",
        town_id: "",
        source_url: "",
        description: "",
        conditions: "",
        award: "",
      },
    };
  },
  computed: {
    meber_id() {
      return this.$route.query.id;
    },
  },
  mounted() {
    if (this.meber_id) {
      this.axios
        .get("/api/policy/product_detail", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.productform = res.data;
          this.productform.member_id = this.meber_id;
          console.log(res.data.type.toString());
          this.productform.type = res.data.type.toString();
        });
    }
  },
  methods: {},
};
</script>

<style scoped="scoped">
.guide h2 {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  margin-top: 30px;
}

.guide .el-textarea__inner {
  border: none !important;
}

.el-link span {
  font-weight: bold;
  color: #0f38ff;
}
p {
  color: #909399;
}
</style>
